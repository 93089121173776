import { ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
// eslint-disable-next-line object-curly-newline
import { getPlayerList, getGroupsActive, getFacilitiesByGroup } from '@api'

// import store from '@/store'

export default function useCommunications() {
  const { t } = useUtils()
  const videoListTable = ref([])
  const fieldOptions = ref([])
  const facilitiesOptions = ref([])
  const groupsOptions = ref([])
  const playerList = ref([])
  const selectedCountry = ref(null)
  const ageFrom = ref(null)
  const ageTo = ref(null)
  const skillLevel = ref(null)
  const selectedProfileC = ref(null)
  const selectedSex = ref(null)

  const { userData } = useCryptoJs()

  const computedTableColumns = computed(() => [
    { text: t('fields.field').toUpperCase(), value: 'name' },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name' },
    { text: t('sports.sport').toUpperCase(), value: 'sport.name', sortable: false },
    { text: t('fields.pricing').toUpperCase(), value: 'pricing_type_str', sortable: false },
    { text: t('fields.hourly_pricing').toUpperCase(), value: 'hourly_pricing', sortable: false },

    { text: t('users.users').toUpperCase(), value: 'fullname' },
    { text: t('facilities.facility').toUpperCase(), value: 'email' },
    { text: t('users.role').toUpperCase(), value: 'role', sortable: false },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name', sortable: false },
    { text: t('status.status').toUpperCase(), value: 'status', sortable: false },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])

  const totalVideoListTable = ref(null)

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const facilityFilter = ref(null)
  const statusFilter = ref(null)
  const fieldFilter = ref(null)
  const groupFilter = ref(null)

  const dateFilter = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const now = ref(new Date())
  const nowDate = ref(
    new Date(now.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const min = ref(+new Date() - 12096e5)
  const minDate = ref(
    new Date(min.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const max = ref(+new Date() + 12096e5)
  const maxDate = ref(
    new Date(max.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const page = ref(1)
  const perPage = ref(100)

  const getVisibleList = () => {
    if (videoListTable.value.length) {
      if (videoListTable.value.length > perPage.value) return videoListTable.value.slice((page.value - 1) * perPage.value, page.value * perPage.value)

      return videoListTable.value
    }

    return []
  }

  const visibleVideoListTable = computed(() => getVisibleList())

  const lengthPagination = () => Math.ceil(totalVideoListTable.value / perPage.value)

  // fetch data
  const fetchPlayerList = async () => {
    const filter = {}
    if (selectedCountry.value !== null && selectedCountry.value !== undefined && selectedCountry.value.length > 0) {
      filter.country = selectedCountry.value
    } else {
      delete filter.country
    }

    if (ageFrom.value !== null && ageFrom.value !== undefined && ageFrom.value > 0) {
      filter.min_age = ageFrom.value
    } else {
      delete filter.min_age
    }

    if (ageTo.value !== null && ageTo.value !== undefined && ageTo.value > 0) {
      filter.max_age = ageTo.value
    } else {
      delete filter.max_age
    }

    if (skillLevel.value !== null && skillLevel.value !== undefined && skillLevel.value.length > 0) {
      filter.skill_level = skillLevel.value
    } else {
      delete filter.skill_level
    }

    if (selectedSex.value !== null && selectedSex.value !== undefined) {
      filter.gender = selectedSex.value
    } else {
      delete filter.gender
    }

    if (selectedProfileC.value !== null && selectedProfileC.value !== undefined) {
      filter.pick_up_profile = selectedProfileC.value
    } else {
      delete filter.pick_up_profile
    }
    filter.facility_id = facilityFilter.value
    const respMetrics = await getPlayerList(filter)
    playerList.value = respMetrics.data
  }

  watch([selectedCountry, ageFrom, ageTo, skillLevel, selectedSex, selectedProfileC], () => {
    fetchPlayerList()
  })

  const fetchGroups = async () => {
    const groups = await getGroupsActive()
    groupsOptions.value = groups.data
    if (groupsOptions.value.length === 1) groupFilter.value = groupsOptions.value[0].id
  }

  const fetchFacilities = async groupId => {
    if (groupId) {
      const facilities = await getFacilitiesByGroup(groupId)
      facilitiesOptions.value = facilities.data
      if (facilitiesOptions.value.length === 1) facilityFilter.value = facilitiesOptions.value[0].id
    } else if (userData.value && userData.value.role !== 'A') {
      const facilities = await getFacilitiesByGroup(userData.value.group_id)
      facilitiesOptions.value = facilities.data
      if (facilitiesOptions.value.length === 1) facilityFilter.value = facilitiesOptions.value[0].id
    } else {
      facilitiesOptions.value = []
    }
  }

  watch([groupFilter], async () => {
    facilitiesOptions.value = []
    facilityFilter.value = 0
    await fetchFacilities(groupFilter.value)
  })

  return {
    fetchGroups,
    fetchFacilities,
    userData,
    videoListTable,
    computedTableColumns,
    searchQuery,
    roleFilter,
    facilityFilter,
    statusFilter,
    fieldFilter,
    groupFilter,
    dateFilter,
    now,
    min,
    max,
    nowDate,
    minDate,
    maxDate,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    facilitiesOptions,
    selectedCountry,
    skillLevel,
    selectedSex,
    selectedProfileC,
    ageFrom,
    ageTo,
    groupsOptions,
    fieldOptions,
    visibleVideoListTable,
    page,
    perPage,
    playerList,

    t,
    lengthPagination,
  }
}
