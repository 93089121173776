<template>
  <div id="communications-list">
    <!-- list filters -->
    <v-card class="mb-8">
      <v-card-title>
        PLAYERS
      </v-card-title>

      <v-row class="app-invoice-preview">
        <v-col
          cols="12"
        >
          <v-card>
            <!-- Header -->
            <v-card-text class="py-7 px-8">
              <v-list
                three-line
                max-height="800"
                style="overflow-y: auto;"
              >
                <!-- <v-subheader
              v-text="t('users.users')"
            ></v-subheader> -->

                <div
                  v-for="(item, index) in dataComputed"
                  :key="index"
                >
                  <v-list-item>
                    <v-list-item-avatar
                      width="60"
                      height="60"
                    >
                      <v-img
                        v-if="item.avatar"
                        :src="item.avatar"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.fullname }}</v-list-item-title>
                      <v-list-item-subtitle class="text-caption">
                        {{ item.fullname }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <!-- <v-list-item-action>
                  <v-list-item-action-text v-text="item.last_view_str"></v-list-item-action-text>
                </v-list-item-action> -->
                  </v-list-item>

                  <v-divider
                    v-if="((dataComputed.length - 1) !=+ index)"
                  />
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const form = ref(null)
    const { t } = useUtils()
    const initData = ref({
      end_time: null,
      facility_address: null,
      facility_avatar: null,
      facility_name: null,
      field_name: null,
      id: null,
      left_thumbnail: null,
      link: null,
      right_thumbnail: null,
      start_time: null,
      status: null,
      status_str: null,
      thumbnail: null,
      transaction: {
        transaction_date: null,
        user: {
          fullname: null,
        },
      },
      video_date: null,
      video_time: null,
      video_time_str: null,
    })

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))

    return {
      // data
      form,

      // computed
      dataComputed,

      // methods
      avatarText,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scope>

.custom-container {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.custom-container > * {
  border-bottom: 1px solid #ccc;
}

.custom-container > *:last-child {
  border-bottom: none;
}

.custom-container > .v-row {
  border-right: 1px solid #ccc;
}

.custom-container > .v-row:last-child {
  border-right: none;
}

div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
