<template>
  <div
    id="communications-main"
  >
    <v-card class="mb-8">
      <v-container class="bg-surface-variant">
        <v-row>
          <v-col
            v-if="userData && userData.role === 'A'"
            cols="12"
            md="3"
          >
            <v-select
              v-model="groupFilter"
              :items="groupsOptions"
              item-text="name"
              item-value="id"
              single-line
              outlined
              dense
              hide-details
              clearable
              :label="t('groups.group')"
              :placeholder="t('select.group')"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="facilityFilter"
              :items="facilitiesOptions"
              item-text="name"
              item-value="id"
              single-line
              outlined
              dense
              hide-details
              clearable
              :label="t('facilities.facility')"
              :placeholder="t('select.facility')"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div style="display: flex; flex-direction: row; margin: 10px;">
              <span style="font-weight: bold; font-size: 22px; color: white;">Total users:</span>
              <span style="font-weight: bold; font-size: 22px; color: white;">1000</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
        color="secondary"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          color="secondary"
          :disabled="tab.disabled"
        >
          <v-icon
            v-if="!tab.hide"
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ t(`${tab.title}`) }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <communications-main-tab
            :data-total="computedTotal"
            :data-list="computedList"
            :data-id="computedID"
            @change="onChangeData"
          ></communications-main-tab>
        </v-tab-item>

        <v-tab-item>
          <communications-list-player-tab
            :data-params="computedData"
          >
          </communications-list-player-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiPlay, mdiBlockHelper, mdiClose, mdiLocker, mdiListStatus, mdiGuitarPickOutline, mdiMessageTextOutline } from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import usePermissions from '@core/utils/usePermissions'
import moment from 'moment'
import CommunicationsMainTab from './CommunicationsMainTab.vue'
import CommunicationsListPlayerTab from './CommunicationsListPlayerTab.vue'
import useCommunications from './useCommunications'

export default {
  components: {
    CommunicationsMainTab,
    CommunicationsListPlayerTab,
  },
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const isDialogVisible = ref(false)
    const isDialogVideoVisible = ref(false)
    const activityControl = ref([])
    const videoOpened = ref({
      video_date_str: '',
    })
    const videoData = ref(null)
    const videoDirection = ref('left')
    const modal = ref(null)

    const tab = ref(0)
    const tabs = ref([
      {
        title: 'Notification',
        icon: mdiMessageTextOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'Player List',
        icon: mdiListStatus,
        disabled: false,
        hide: false,
      },
    ])
    const {
      userData,
      fetchGroups,
      fetchFacilities,

      searchQuery,
      facilityFilter,
      fieldFilter,
      groupFilter,
      dateFilter,

      now,
      min,
      max,
      nowDate,
      maxDate,
      minDate,
      groupsOptions,
      facilitiesOptions,
      fieldOptions,
      selectedCountry,
      selectedSex,
      ageFrom,
      ageTo,
      skillLevel,
      selectedProfileC,

      playerList,
      videoListTable,
      totalVideoListTable,
      lengthPagination,
      visibleVideoListTable,
      page,
      perPage,

    } = useCommunications()
    const computedDateFormat = computed(() => moment(dateFilter.value).format('ll'))
    const computedData = computed(() => (playerList.value ? playerList.value.players : []))
    const computedTotal = computed(() => (playerList.value ? playerList.value.total_players : 0))
    const computedList = computed(() => (playerList.value ? playerList.value.players : []))
    const computedID = computed(() => (facilityFilter.value ? facilityFilter.value : null))

    const onChangeData = value => {
      if (value.tipo === 'c') {
        selectedCountry.value = value.valor.value
      } else if (value.tipo === 'f') {
        ageFrom.value = value.valor.value
      } else if (value.tipo === 't') {
        ageTo.value = value.valor.value
      } else if (value.tipo === 'k') {
        skillLevel.value = value.valor.value
      } else if (value.tipo === 's') {
        selectedSex.value = value.valor.value
      } else if (value.tipo === 'p') {
        selectedProfileC.value = value.valor.value
      }
    }

    onMounted(async () => {
      if (userData.value && userData.value.role !== 'A') {
        await fetchFacilities()
      } else {
        await fetchGroups()
        await fetchFacilities()
      }
    })

    return {
      userData,
      tabs,
      tab,
      searchQuery,
      groupFilter,
      facilityFilter,
      fieldFilter,
      computedData,
      computedTotal,
      computedID,
      computedList,

      selectedCountry,
      computedDateFormat,
      dateFilter,
      now,
      min,
      max,
      nowDate,
      maxDate,
      minDate,
      groupsOptions,
      facilitiesOptions,
      fieldOptions,
      onChangeData,

      isDialogVisible,
      isDialogVideoVisible,
      activityControl,
      videoOpened,
      videoData,
      videoDirection,

      page,
      perPage,
      modal,
      lengthPagination,
      videoListTable,
      totalVideoListTable,
      visibleVideoListTable,

      hasPermission,

      // i18n
      t,

      // icons
      icons: {
        mdiPlay,
        mdiBlockHelper,
        mdiClose,
        mdiLocker,
        mdiListStatus,
        mdiGuitarPickOutline,
        mdiMessageTextOutline,
      },
    }
  },
}
</script>

<style lang="scss" scope>
@import '@core/preset/preset/apps/user.scss';

.v-responsive__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.v-image__image {
  background-size: 100% 100%;
}

div.v-slide-group__prev,
div.v-slide-group__next {
  min-width: 30px;

  svg.v-icon__svg {
    height: 20px;
    width: 20px;
    fill: currentColor;
  }
}

.v-input.detail-select.v-input__control.v-input__slot {
  min-height: 30px;
  height: 30px;
}

.detail-select.v-input__control.v-input__slot,
.detail-select.v-list-item__action .v-input__control {
  min-height: 30px;
  height: 30px;
}

.v-window__prev,
.v-window__next {
  button {
    font-size: 20px !important;
  }

  v-icon {
    font-size: 20px;
  }

  .lock-icon {
    position: absolute !important;
    top: 35% !important;
    right: 50% !important;
    transform: translate(50%, 50%) !important;
  }

  div .v-input__append-outer {
    margin: 0;
  }

  .width-full {
    width: -webkit-fill-available;
    width: fit-content;
  }
}

.absolute-chip {
  bottom: 30px;
  right: 10px;
  position: absolute;
}

.p-card {
  padding: 10px !important;
  padding-top: 0 !important;
}

.mt-filter-row {
  margin-top: 0px !important;
}

.rounded-icon {
  background-color: #00000099;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-icon-sm {
  background-color: #00000099;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 30px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 30px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 50px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
    bottom: 30px;
  }

  .absolute-chip {
    right: 10px;
    bottom: 40px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 40px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
</style>
