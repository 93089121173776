<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-container class="bg-surface-variant">
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            sm="1"
            cols="12"
            style="text-align: right;"
          >
            <v-icon style="color: red;">
              {{ icons.mdiCircle }}
            </v-icon>
          </v-col>
          <v-col
            sm="4"
            cols="12"
            style="font-size: 18px;"
          >
            Send push notifications to all users with the following conditions:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            cols="12"
          >
            <div style="display: flex; flex-direction: row;">
              <v-select
                v-model="selectedCountry"
                clearable
                dense
                outlined
                :items="[
                  { name: 'Afghanistan', id: 'af' },
                  { name: 'Albania', id: 'al' },
                  { name: 'Algeria', id: 'dz' },
                  { name: 'Andorra', id: 'ad' },
                  { name: 'Angola', id: 'ao' },
                  { name: 'Antigua and Barbuda', id: 'ag' },
                  { name: 'Argentina', id: 'ar' },
                  { name: 'Armenia', id: 'am' },
                  { name: 'Australia', id: 'au' },
                  { name: 'Austria', id: 'at' },
                  { name: 'Azerbaijan', id: 'az' },
                  { name: 'Bahamas', id: 'bs' },
                  { name: 'Bahrain', id: 'bh' },
                  { name: 'Bangladesh', id: 'bd' },
                  { name: 'Barbados', id: 'bb' },
                  { name: 'Belarus', id: 'by' },
                  { name: 'Belgium', id: 'be' },
                  { name: 'Belize', id: 'bz' },
                  { name: 'Benin', id: 'bj' },
                  { name: 'Bhutan', id: 'bt' },
                  { name: 'Bolivia', id: 'bo' },
                  { name: 'Bosnia and Herzegovina', id: 'ba' },
                  { name: 'Botswana', id: 'bw' },
                  { name: 'Brazil', id: 'br' },
                  { name: 'Brunei', id: 'bn' },
                  { name: 'Bulgaria', id: 'bg' },
                  { name: 'Burkina Faso', id: 'bf' },
                  { name: 'Burundi', id: 'bi' },
                  { name: 'Cabo Verde', id: 'cv' },
                  { name: 'Cambodia', id: 'kh' },
                  { name: 'Cameroon', id: 'cm' },
                  { name: 'Canada', id: 'ca' },
                  { name: 'Central African Republic', id: 'cf' },
                  { name: 'Chad', id: 'td' },
                  { name: 'Chile', id: 'cl' },
                  { name: 'China', id: 'cn' },
                  { name: 'Colombia', id: 'co' },
                  { name: 'Comoros', id: 'km' },
                  { name: 'Congo (Brazzaville)', id: 'cg' },
                  { name: 'Congo (Kinshasa)', id: 'cd' },
                  { name: 'Costa Rica', id: 'cr' },
                  { name: 'Croatia', id: 'hr' },
                  { name: 'Cuba', id: 'cu' },
                  { name: 'Cyprus', id: 'cy' },
                  { name: 'Czech Republic', id: 'cz' },
                  { name: 'Denmark', id: 'dk' },
                  { name: 'Djibouti', id: 'dj' },
                  { name: 'Dominica', id: 'dm' },
                  { name: 'Dominican Republic', id: 'do' },
                  { name: 'Ecuador', id: 'ec' },
                  { name: 'Egypt', id: 'eg' },
                  { name: 'El Salvador', id: 'sv' },
                  { name: 'Equatorial Guinea', id: 'gq' },
                  { name: 'Eritrea', id: 'er' },
                  { name: 'Estonia', id: 'ee' },
                  { name: 'Ethiopia', id: 'et' },
                  { name: 'Fiji', id: 'fj' },
                  { name: 'Finland', id: 'fi' },
                  { name: 'France', id: 'fr' },
                  { name: 'Gabon', id: 'ga' },
                  { name: 'Gambia', id: 'gm' },
                  { name: 'Georgia', id: 'ge' },
                  { name: 'Germany', id: 'de' },
                  { name: 'Ghana', id: 'gh' },
                  { name: 'Greece', id: 'gr' },
                  { name: 'Grenada', id: 'gd' },
                  { name: 'Guatemala', id: 'gt' },
                  { name: 'Guinea', id: 'gn' },
                  { name: 'Guinea-Bissau', id: 'gw' },
                  { name: 'Guyana', id: 'gy' },
                  { name: 'Haiti', id: 'ht' },
                  { name: 'Holy See', id: 'va' },
                  { name: 'Honduras', id: 'hn' },
                  { name: 'Hungary', id: 'hu' },
                  { name: 'Iceland', id: 'is' },
                  { name: 'India', id: 'in' },
                  { name: 'Indonesia', id: 'id' },
                  { name: 'Iran', id: 'ir' },
                  { name: 'Iraq', id: 'iq' },
                  { name: 'Ireland', id: 'ie' },
                  { name: 'Israel', id: 'il' },
                  { name: 'Italy', id: 'it' },
                  { name: 'Ivory Coast', id: 'ci' },
                  { name: 'Jamaica', id: 'jm' },
                  { name: 'Japan', id: 'jp' },
                  { name: 'Jordan', id: 'jo' },
                  { name: 'Kazakhstan', id: 'kz' },
                  { name: 'Kenya', id: 'ke' },
                  { name: 'Kiribati', id: 'ki' },
                  { name: 'Kuwait', id: 'kw' },
                  { name: 'Kyrgyzstan', id: 'kg' },
                  { name: 'Laos', id: 'la' },
                  { name: 'Latvia', id: 'lv' },
                  { name: 'Lebanon', id: 'lb' },
                  { name: 'Lesotho', id: 'ls' },
                  { name: 'Liberia', id: 'lr' },
                  { name: 'Libya', id: 'ly' },
                  { name: 'Liechtenstein', id: 'li' },
                  { name: 'Lithuania', id: 'lt' },
                  { name: 'Luxembourg', id: 'lu' },
                  { name: 'Madagascar', id: 'mg' },
                  { name: 'Malawi', id: 'mw' },
                  { name: 'Malaysia', id: 'my' },
                  { name: 'Maldives', id: 'mv' },
                  { name: 'Mali', id: 'ml' },
                  { name: 'Malta', id: 'mt' },
                  { name: 'Marshall Islands', id: 'mh' },
                  { name: 'Mauritania', id: 'mr' },
                  { name: 'Mauritius', id: 'mu' },
                  { name: 'Mexico', id: 'mx' },
                  { name: 'Micronesia', id: 'fm' },
                  { name: 'Moldova', id: 'md' },
                  { name: 'Monaco', id: 'mc' },
                  { name: 'Mongolia', id: 'mn' },
                  { name: 'Montenegro', id: 'me' },
                  { name: 'Morocco', id: 'ma' },
                  { name: 'Mozambique', id: 'mz' },
                  { name: 'Myanmar', id: 'mm' },
                  { name: 'Namibia', id: 'na' },
                  { name: 'Nauru', id: 'nr' },
                  { name: 'Nepal', id: 'np' },
                  { name: 'Netherlands', id: 'nl' },
                  { name: 'New Zealand', id: 'nz' },
                  { name: 'Nicaragua', id: 'ni' },
                  { name: 'Niger', id: 'ne' },
                  { name: 'Nigeria', id: 'ng' },
                  { name: 'North Korea', id: 'kp' },
                  { name: 'North Macedonia', id: 'mk' },
                  { name: 'Norway', id: 'no' },
                  { name: 'Oman', id: 'om' },
                  { name: 'Pakistan', id: 'pk' },
                  { name: 'Palau', id: 'pw' },
                  { name: 'Palestine', id: 'ps' },
                  { name: 'Panama', id: 'pa' },
                  { name: 'Papua New Guinea', id: 'pg' },
                  { name: 'Paraguay', id: 'py' },
                  { name: 'Peru', id: 'pe' },
                  { name: 'Philippines', id: 'ph' },
                  { name: 'Poland', id: 'pl' },
                  { name: 'Portugal', id: 'pt' },
                  { name: 'Qatar', id: 'qa' },
                  { name: 'Romania', id: 'ro' },
                  { name: 'Russia', id: 'ru' },
                  { name: 'Rwanda', id: 'rw' },
                  { name: 'Saint Kitts and Nevis', id: 'kn' },
                  { name: 'Saint Lucia', id: 'lc' },
                  { name: 'Saint Vincent and the Grenadines', id: 'vc' },
                  { name: 'Samoa', id: 'ws' },
                  { name: 'San Marino', id: 'sm' },
                  { name: 'Sao Tome and Principe', id: 'st' },
                  { name: 'Saudi Arabia', id: 'sa' },
                  { name: 'Senegal', id: 'sn' },
                  { name: 'Serbia', id: 'rs' },
                  { name: 'Seychelles', id: 'sc' },
                  { name: 'Sierra Leone', id: 'sl' },
                  { name: 'Singapore', id: 'sg' },
                  { name: 'Slovakia', id: 'sk' },
                  { name: 'Slovenia', id: 'si' },
                  { name: 'Solomon Islands', id: 'sb' },
                  { name: 'Somalia', id: 'so' },
                  { name: 'South Africa', id: 'za' },
                  { name: 'South Korea', id: 'kr' },
                  { name: 'South Sudan', id: 'ss' },
                  { name: 'Spain', id: 'es' },
                  { name: 'Sri Lanka', id: 'lk' },
                  { name: 'Sudan', id: 'sd' },
                  { name: 'Suriname', id: 'sr' },
                  { name: 'Swaziland', id: 'sz' },
                  { name: 'Sweden', id: 'se' },
                  { name: 'Switzerland', id: 'ch' },
                  { name: 'Syria', id: 'sy' },
                  { name: 'Tajikistan', id: 'tj' },
                  { name: 'Tanzania', id: 'tz' },
                  { name: 'Thailand', id: 'th' },
                  { name: 'Timor-Leste', id: 'tl' },
                  { name: 'Togo', id: 'tg' },
                  { name: 'Tonga', id: 'to' },
                  { name: 'Trinidad and Tobago', id: 'tt' },
                  { name: 'Tunisia', id: 'tn' },
                  { name: 'Turkey', id: 'tr' },
                  { name: 'Turkmenistan', id: 'tm' },
                  { name: 'Tuvalu', id: 'tv' },
                  { name: 'Uganda', id: 'ug' },
                  { name: 'Ukraine', id: 'ua' },
                  { name: 'United Arab Emirates', id: 'ae' },
                  { name: 'United Kingdom', id: 'gb' },
                  { name: 'United States', id: 'us' },
                  { name: 'Uruguay', id: 'uy' },
                  { name: 'Uzbekistan', id: 'uz' },
                  { name: 'Vanuatu', id: 'vu' },
                  { name: 'Venezuela', id: 've' },
                  { name: 'Vietnam', id: 'vn' },
                  { name: 'Yemen', id: 'ye' },
                  { name: 'Zambia', id: 'zm' },
                  { name: 'Zimbabwe', id: 'zw' }
                ]
                "
                item-text="name"
                item-value="id"
                label="Country of birth"
                multiple
                @change="changeData('c')"
              ></v-select>
            </div>
          </v-col>
          <v-col
            sm="6"
            cols="12"
          >
            <div style="display: flex; flex-direction: row;">
              <v-container class="bg-surface-variant">
                <v-row>
                  <v-col
                    sm="3"
                    cols="12"
                    style="padding: 0px;"
                  >
                    <span style="font-weight: bold; font-size: 18px; color: white;">Age rangue </span>
                  </v-col>
                  <v-col
                    sm="3"
                    cols="12"
                    style="padding: 0px;"
                  >
                    <v-text-field
                      v-model="ageFrom"
                      label="from"
                      dense
                      outlined
                      type="number"
                      step="1"
                      style="padding: 0px;"
                      @change="changeData('f')"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    sm="1"
                    cols="12"
                    style="text-align: center; padding: 0px;"
                  >
                  </v-col>
                  <v-col
                    sm="4"
                    cols="12"
                    style="padding: 0px;"
                  >
                    <v-text-field
                      v-model="ageTo"
                      label="To"
                      dense
                      outlined
                      type="number"
                      step="1"
                      style="padding: 0px;"
                      @change="changeData('t')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            cols="12"
          >
            <v-container class="bg-surface-variant">
              <v-row>
                <v-col
                  sm="7"
                  cols="12"
                  style="padding: 0px;"
                >
                  <v-select
                    v-model="skillLevel"
                    :items="['0+', '1+', '2+', '3+', '4+', '5+']"
                    label="Skill level"
                    dense
                    multiple
                    outlined
                    @change="changeData('k')"
                  ></v-select>
                </v-col>
                <v-col
                  sm="5"
                  cols="12"
                  style="padding: 0px;"
                >
                  <v-select
                    v-model="selectedSex"
                    :items="['Male', 'Female']"
                    label="Sex"
                    outlined
                    dense
                    style="padding-left: 10px;"
                    @change="changeData('s')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col
            sm="6"
            cols="12"
          >
            <div style="display: flex; flex-direction: row;">
              <v-select
                v-model="selectedProfileC"
                :items="['Yes', 'No']"
                label="Pick up game profile completed"
                dense
                outlined
                style="padding-left: 10px;"
                @change="changeData('p')"
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            cols="12"
          >
            <v-textarea
              label="Add custom message"
              outlined
              dense
              variant="outlined"
            ></v-textarea>
          </v-col>
          <v-col
            sm="6"
            cols="12"
          >
            <v-container class="bg-surface-variant">
              <v-row>
                <v-col
                  sm="6"
                  cols="12"
                  style="border: 1px solid green;"
                >
                  <span style="font-weight: bold; font-size: 18px; color: white;">Total players: {{ totalComputed }} </span>
                </v-col>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-btn
                    color="secondary"
                    class="me-3"
                    @click="onCreateGroup()"
                  >
                    <v-icon
                      size="18"
                      class="me-1"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Create new group</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            cols="12"
          >
            <v-text-field
              dense
              outlined
              label="Add links"
              variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            cols="12"
          >
            <v-btn
              color="secondary"
              class="me-3"
            >
              <span>Send invite</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown, mdiCircle, mdiPlus } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'

export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
    dataId: {
      type: Number,
      default: 0,
    },
    dataTotal: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const { t } = useUtils()
    const { router } = useRouter()

    const selectedCountry = ref(null)
    const ageFrom = ref(null)
    const ageTo = ref(null)
    const skillLevel = ref(null)
    const selectedProfileC = ref(null)
    const selectedSex = ref(null)
    const totalComputed = computed(() => (props.dataTotal ? props.dataTotal : 0))
    const listComputed = computed(() => (props.dataList ? props.dataList : []))
    const IDComputed = computed(() => (props.dataId ? props.dataId : 0))

    const onCreateGroup = () => {
      router.push({
        name: 'views-players-group-form',
        params: {
          option: 1,
          pageParams: null,
          filterParams: null,
          backTo: 'views-console-communications',
          users: listComputed.value.map(item => item.username),
          facilityId: IDComputed.value,
        },
      })
    }

    const changeData = valor => {
      let objectChanged = null
      if (valor === 'c') {
        objectChanged = selectedCountry
      } else if (valor === 'f') {
        objectChanged = ageFrom
      } else if (valor === 't') {
        objectChanged = ageTo
      } else if (valor === 'k') {
        objectChanged = skillLevel
      } else if (valor === 's') {
        objectChanged = selectedSex
      } else if (valor === 'p') {
        objectChanged = selectedProfileC
      }

      emit('change', { tipo: valor, valor: objectChanged })
    }

    return {
      // data
      form,
      selectedCountry,
      skillLevel,
      selectedProfileC,
      selectedSex,
      ageFrom,
      ageTo,
      changeData,
      onCreateGroup,

      // computed
      totalComputed,
      listComputed,
      IDComputed,

      // methods
      avatarText,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
        mdiCircle,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scope>
div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
